// import React, { useState } from "react";
// import HTMLReactParser from "html-react-parser";
// import millify from "millify";
// import { Col, Row, Typography, Select } from "antd";
// import {
//   useGetCryptoDetailsQuery,
//   useGetCryptoHistoryQuery,
// } from "../services/cryptoApi";
// import { useParams } from "react-router-dom";
// import {
//   MoneyCollectOutlined,
//   DollarCircleOutlined,
//   FundOutlined,
//   ExclamationCircleOutlined,
//   StopOutlined,
//   TrophyOutlined,
//   CheckOutlined,
//   NumberOutlined,
//   ThunderboltOutlined,
// } from "@ant-design/icons";
// import LineChart from "./LineChart";
// import Loader from "./Loader";
// const { Title, Text } = Typography;
// const { Option } = Select;

// const CryptoDetails = () => {
//   const { coinId } = useParams();
//   console.log("id", coinId);
//   const [timeState, setTimeState] = useState("7d");
//   const { data, isFetching } = useGetCryptoDetailsQuery(coinId);
//   const cryptoDetails = data?.data?.coin;
//   const time = ["3h", "24h", "7d", "30d", "1y", "3m", "3y", "5y"];
//   const { data: coinHistory } = useGetCryptoHistoryQuery({ coinId, timeState });

//   if (isFetching) {
//     return <Loader />;
//   }
//   const stats = [
//     {
//       title: "Price to USD",
//       value: `$ ${cryptoDetails?.price && millify(cryptoDetails?.price)}`,
//       icon: <DollarCircleOutlined />,
//     },
//     { title: "Rank", value: cryptoDetails?.rank, icon: <NumberOutlined /> },
//     {
//       title: "24h Volume",
//       value: `$ ${cryptoDetails?.volume && millify(cryptoDetails?.volume)}`,
//       icon: <ThunderboltOutlined />,
//     },
//     {
//       title: "Market Cap",
//       value: `$ ${
//         cryptoDetails?.marketCap && millify(cryptoDetails?.marketCap)
//       }`,
//       icon: <DollarCircleOutlined />,
//     },
//     {
//       title: "All-time-high(daily avg.)",
//       value: `$ ${
//         cryptoDetails?.allTimeHigh?.price &&
//         millify(cryptoDetails?.allTimeHigh?.price)
//       }`,
//       icon: <TrophyOutlined />,
//     },
//   ];

//   const genericStats = [
//     {
//       title: "Number Of Markets",
//       value: cryptoDetails?.numberOfMarkets,
//       icon: <FundOutlined />,
//     },
//     {
//       title: "Number Of Exchanges",
//       value: cryptoDetails?.numberOfExchanges,
//       icon: <MoneyCollectOutlined />,
//     },
//     {
//       title: "Aprroved Supply",
//       value: cryptoDetails?.supply?.confirmed ? (
//         <CheckOutlined />
//       ) : (
//         <StopOutlined />
//       ),
//       icon: <ExclamationCircleOutlined />,
//     },
//     {
//       title: "Total Supply",
//       value: `$ ${
//         cryptoDetails?.supply?.total && millify(cryptoDetails?.supply?.total)
//       }`,
//       icon: <ExclamationCircleOutlined />,
//     },
//     {
//       title: "Circulating Supply",
//       value: `$ ${
//         cryptoDetails?.supply?.circulating &&
//         millify(cryptoDetails?.supply?.circulating)
//       }`,
//       icon: <ExclamationCircleOutlined />,
//     },
//   ];
//   return (
//     <Col className="coin-detail-container">
//       <Col className="coin-heading-container">
//         <Title level={2} className="coin-name">
//           {cryptoDetails?.name}({cryptoDetails?.slug}) Price
//         </Title>
//         <p>
//           {cryptoDetails?.name} live price in US dollars. View value statistics,
//           market cap and supply
//         </p>
//       </Col>
//       <Select
//         defaultValue="7d"
//         className="select-timeperiod"
//         placeholder="Select Time Period"
//         onChange={(value) => setTimeState(value)}
//       >
//         {time.map((date) => (
//           <Option key={date}>{date}</Option>
//         ))}
//       </Select>
//       <LineChart
//         coinHistory={coinHistory}
//         currentPrice={millify(cryptoDetails?.price)}
//         coinName={cryptoDetails?.name}
//       />
//       <Col className="stats-container">
//         <Col className="coin-value-statistics">
//           <Col className="coin-value-statistics-heading">
//             <Title level={3} className="coin-details-heading">
//               {cryptoDetails?.name} Value Statistics
//             </Title>
//             <p>
//               An overview showing the stats statistics of {cryptoDetails?.name}{" "}
//               such as the base and quote currency, the rank , and trading volume
//             </p>
//           </Col>
//           {stats.map(({ icon, title, value }) => (
//             <Col className="coin-stats">
//               <Col className="coin-stats-name">
//                 <Text>{icon}</Text>
//                 <Text>{title}</Text>
//               </Col>
//               <Text className="stats">{value}</Text>
//             </Col>
//           ))}
//         </Col>
//         <Col className="other-stats-info">
//           <Col className="coin-value-statistics-heading">
//             <Title level={3} className="coin-details">
//               {cryptoDetails?.name} Other Stats Info
//             </Title>
//             <p>
//               An overview showing the statistics of {cryptoDetails?.name}, such
//               as the base and quote currency, the rank, and trading volume{" "}
//             </p>
//           </Col>
//           {genericStats.map(({ icon, title, value }) => (
//             <Col className="coin-stats">
//               <Col className="coin-stats-name">
//                 <Text>{icon}</Text>
//                 <Text>{title}</Text>
//               </Col>
//               <Text className="stats">{value}</Text>
//             </Col>
//           ))}
//         </Col>
//       </Col>
//       <Col className="coin-desc-link">
//         <Row className="coin-desc">
//           <Title level={3} className="coin-details-heading">
//             What is {cryptoDetails?.name}?
//           </Title>
//           {HTMLReactParser(`${cryptoDetails?.description}`)}
//           {/* {cryptoDetails?.description} */}
//         </Row>
//         <Col className="coin-desc-link">
//           <Title level={3} className="coin-details-heading">
//             {cryptoDetails?.name} Links
//           </Title>
//           {cryptoDetails?.links?.map((link) => (
//             <Row className="coin-link" key={link?.name}>
//               <Title level={5} className="link-name">
//                 {link?.type}
//               </Title>
//               <a href={link?.url} target="_blank" rel="noreferrer">
//                 {link?.name}
//               </a>
//             </Row>
//           ))}
//         </Col>
//       </Col>
//     </Col>
//   );
// };

// export default CryptoDetails;

import React, { useState } from "react";
import HTMLReactParser from "html-react-parser";
import { useParams } from "react-router-dom";
import millify from "millify";
import { Col, Row, Typography, Select } from "antd";
import {
  MoneyCollectOutlined,
  DollarCircleOutlined,
  FundOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
  TrophyOutlined,
  CheckOutlined,
  NumberOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";

import {
  useGetCryptoDetailsQuery,
  useGetCryptoHistoryQuery,
} from "../services/cryptoApi";
import Loader from "./Loader";
import LineChart from "./LineChart";

const { Title, Text } = Typography;
const { Option } = Select;

const CryptoDetails = () => {
  const { coinId } = useParams();
  const [timeperiod, setTimeperiod] = useState("7d");
  const { data, isFetching } = useGetCryptoDetailsQuery(coinId);
  const { data: coinHistory } = useGetCryptoHistoryQuery({
    coinId,
    timeperiod,
  });
  const cryptoDetails = data?.data?.coin;

  if (isFetching) return <Loader />;

  const time = ["3h", "24h", "7d", "30d", "1y", "3m", "3y", "5y"];

  const stats = [
    {
      title: "Price to USD",
      value: `$ ${cryptoDetails?.price && millify(cryptoDetails?.price)}`,
      icon: <DollarCircleOutlined />,
    },
    { title: "Rank", value: cryptoDetails?.rank, icon: <NumberOutlined /> },
    {
      title: "24h Volume",
      value: `$ ${cryptoDetails?.volume && millify(cryptoDetails?.volume)}`,
      icon: <ThunderboltOutlined />,
    },
    {
      title: "Market Cap",
      value: `$ ${
        cryptoDetails?.marketCap && millify(cryptoDetails?.marketCap)
      }`,
      icon: <DollarCircleOutlined />,
    },
    {
      title: "All-time-high(daily avg.)",
      value: `$ ${
        cryptoDetails?.allTimeHigh?.price &&
        millify(cryptoDetails?.allTimeHigh?.price)
      }`,
      icon: <TrophyOutlined />,
    },
  ];

  const genericStats = [
    {
      title: "Number Of Markets",
      value: cryptoDetails?.numberOfMarkets,
      icon: <FundOutlined />,
    },
    {
      title: "Number Of Exchanges",
      value: cryptoDetails?.numberOfExchanges,
      icon: <MoneyCollectOutlined />,
    },
    {
      title: "Aprroved Supply",
      value: cryptoDetails?.supply?.confirmed ? (
        <CheckOutlined />
      ) : (
        <StopOutlined />
      ),
      icon: <ExclamationCircleOutlined />,
    },
    {
      title: "Total Supply",
      value: `$ ${
        cryptoDetails?.supply?.total && millify(cryptoDetails?.supply?.total)
      }`,
      icon: <ExclamationCircleOutlined />,
    },
    {
      title: "Circulating Supply",
      value: `$ ${
        cryptoDetails?.supply?.circulating &&
        millify(cryptoDetails?.supply?.circulating)
      }`,
      icon: <ExclamationCircleOutlined />,
    },
  ];

  return (
    <Col className="coin-detail-container">
      <Col className="coin-heading-container">
        <Title level={2} className="coin-name">
          {data?.data?.coin.name} ({data?.data?.coin.symbol}) Price
        </Title>
        <p>
          {cryptoDetails.name} live price in US Dollar (USD). View value
          statistics, market cap and supply.
        </p>
      </Col>
      <Select
        defaultValue="7d"
        className="select-timeperiod"
        placeholder="Select Timeperiod"
        onChange={(value) => setTimeperiod(value)}
      >
        {time.map((date) => (
          <Option key={date}>{date}</Option>
        ))}
      </Select>
      <LineChart
        coinHistory={coinHistory}
        currentPrice={millify(cryptoDetails?.price)}
        coinName={cryptoDetails?.name}
      />
      <Col className="stats-container">
        <Col className="coin-value-statistics">
          <Col className="coin-value-statistics-heading">
            <Title level={3} className="coin-details-heading">
              {cryptoDetails.name} Value Statistics
            </Title>
            <p>
              An overview showing the statistics of {cryptoDetails.name}, such
              as the base and quote currency, the rank, and trading volume.
            </p>
          </Col>
          {stats.map(({ icon, title, value }) => (
            <Col className="coin-stats">
              <Col className="coin-stats-name">
                <Text>{icon}</Text>
                <Text>{title}</Text>
              </Col>
              <Text className="stats">{value}</Text>
            </Col>
          ))}
        </Col>
        <Col className="other-stats-info">
          <Col className="coin-value-statistics-heading">
            <Title level={3} className="coin-details-heading">
              Other Stats Info
            </Title>
            <p>
              An overview showing the statistics of {cryptoDetails.name}, such
              as the base and quote currency, the rank, and trading volume.
            </p>
          </Col>
          {genericStats.map(({ icon, title, value }) => (
            <Col className="coin-stats">
              <Col className="coin-stats-name">
                <Text>{icon}</Text>
                <Text>{title}</Text>
              </Col>
              <Text className="stats">{value}</Text>
            </Col>
          ))}
        </Col>
      </Col>
      <Col className="coin-desc-link">
        <Row className="coin-desc">
          <Title level={3} className="coin-details-heading">
            What is {cryptoDetails.name}?
          </Title>
          {HTMLReactParser(cryptoDetails.description)}
        </Row>
        <Col className="coin-links">
          <Title level={3} className="coin-details-heading">
            {cryptoDetails.name} Links
          </Title>
          {cryptoDetails.links?.map((link) => (
            <Row className="coin-link" key={link.name}>
              <Title level={5} className="link-name">
                {link.type}
              </Title>
              <a href={link.url} target="_blank" rel="noreferrer">
                {link.name}
              </a>
            </Row>
          ))}
        </Col>
      </Col>
    </Col>
  );
};

export default CryptoDetails;
